#about {
    background: var(--color-bg);
    padding: 1rem;
}

.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 11%;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    /* background: linear-gradient(45deg, transparent, #b5c6fe, transparent); */
    display: grid;
    place-items: center;
}

#about h2 {
    color: var(--color-bg-variant);
}

.about__me__image {
    border-radius: 2rem;
    overflow: hidden;
    transition: var(--transition);
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about__card {
    background: var(--color-light);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
    box-shadow: 0 1.5rem 1rem rgba(0, 0, 0, 0.1);
}

.about__card:hover {
    cursor: default;
    transform: scale(1.05);
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.3);
}

.about__icon {
    color: var(--color-primary);
    font-size: 2rem;
    margin-bottom: 1rem;
}

.about__card h5 {
    font-size: 0.95rem;
    color: #090132;
}

.about__card small {
    font-size: 0.7rem;
    color: var(--color-bg-variant);
}

.about__content p {
    margin: 2rem 0 2.6rem;
    color: #090132;
}

/* --- MEDIA QUERIES (Medium devices/Tablets) --- */

@media screen and (max-width: 1024px) {

    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about__content p {
        padding-top: 2rem;
        margin: 1rem 0 1.5rem;
    }
}
  
/* --- MEDIA QUERIES (Small devices/Phones) --- */
  
@media screen and (max-width: 600px) {

    .about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about__cards {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .about__content {
        text-align: center;
    }

    .about__content p {
        margin: 1.5rem 0;
    }
}