#portfolio h2 {
    color: var(--color-copy);
}
.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-light);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.portfolio__item:hover {
    transition: var(--transition);
    background: #FFFFFF;
}

.portfolio__item__image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem 0rem 0.5rem;
    color: #B5C6FE;
    padding-top: 0.5rem;
}

.portfolio__item p {
    display: flex;
    margin-bottom: 1rem;
    color: var(--color-copy);
    padding-top: 0.5rem;
}

.portfolio__item__cta {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 1rem;
}

/* --- MEDIA QUERIES (Medium devices/Tablets) --- */

@media screen and (max-width: 1024px) {

    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}
  
/* --- MEDIA QUERIES (Small devices/Phones) --- */
  
@media screen and (max-width: 600px) {

    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

}