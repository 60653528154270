nav {
    background: rgba(181, 198, 254, 0.3);
    width: max-content;
    padding: 0.5rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 1.2rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    color: var(--color-copy);
    font-size: 1.1rem;
    align-items: center;
}

.icon__copy {
    margin-top: -2px;
    font-size: 8px;
}

/* --- MEDIA QUERIES (Medium devices/Tablets) --- */

@media screen and (max-width: 1024px) {

    nav {
        border-radius: 0 0 0 0;
        width: 100%;
        gap: 7rem;
        bottom: 0rem;
    }

}
  
/* --- MEDIA QUERIES (Small devices/Phones) --- */
  
@media screen and (max-width: 600px) {

    nav {
        border-radius: 0 0 0 0;
        width: 100%;
        gap: 1rem;
        bottom: 0rem;
    }

    nav a {
        padding-top: 0.2rem;
    }
}