#services {
    background: var(--color-white);
    padding-bottom: 5rem;
    padding-top: 3rem;
}

#services h2 {
    color: var(--color-copy);
}

.services__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    background: var(--color-white);
}

.service {
    background: var(--color-white);
    border-radius: 2rem;
    border: 1px solid transparent var(--color-primary);
    height: fit-content;
    transition: var(--transition);
}

.service:hover {
    background: transparent;
    border-color: transparent;
    cursor: default;
    transform: scale(1.06);
    transition: var(--transition);
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.3);
}

.service__head {
    background: #B5C6FE;
    padding: 2rem;
    border-radius: 2rem 2rem 0 0 ;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.service__head h3 {
    color: var(--color-copy);
    font-size: 1rem;
    text-align: center;
}

.service__list {
    padding: 2rem;
    background: var(--color-light);
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.service__list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

.service__list__icon {
    color: var(--color-primary);
    margin-top: 2px;
    margin-left: 1rem;
}

.service__list p {
    font-size: 0.9rem;
    color: var(--color-copy);
    margin-left: 2rem;
}

/* --- MEDIA QUERIES (Medium devices/Tablets) --- */

@media screen and (max-width: 1024px) {

    .services__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .service {
        height: auto;
    }

}
  
/* --- MEDIA QUERIES (Small devices/Phones) --- */
  
@media screen and (max-width: 600px) {

    .services__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

}