#testimonials {
    background: var(--color-white);
    padding: 3rem 0 3rem 0;
}

#testimonials h2 {
    color: var(--color-copy);
}

.container.testimonials__container {
    width: 60%;
    padding-bottom: 4rem;
}

.client__avatar {
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-variant);
}

.testimonial {
    background: #b5c6fe;
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.client__review {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}

.client__name {
    color: var(--color-light);
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
}

.swiper-pagination-clickable .swiper-pagination-bullet:hover {
    transform: scale(1.1);
    background: var(--color-primary);
}

.third__party__container {
    width: 60%;
    padding: 3rem 3rem 0 3rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    background: var(--color-white);
}

.third__party {
    background: var(--color-white);
    border-radius: 2rem;
    border: 1px solid transparent var(--color-primary);
    height: fit-content;
    transition: var(--transition);
}

.third__party:hover {
    cursor: pointer;
}

/* --- MEDIA QUERIES (Medium devices/Tablets) --- */

@media screen and (max-width: 1024px) {

    .testimonials__container {
        width: 60%;
    }

    .third__party__container {
        grid-template-columns: 1fr;
        gap: 0;
    }
}
  
/* --- MEDIA QUERIES (Small devices/Phones) --- */
  
@media screen and (max-width: 600px) {

    .testimonials__container {
        width: var(--container-width-sm);
    }

    .client__review {
        width: var(--container-width-sm);
    }

    .third__party__container {
        grid-template-columns: 1fr;
        gap: 0;
    }
}