header {
    margin-top: 5rem;
    padding-bottom: 3rem;
    overflow: hidden;
    background: var(--color-bg);
}

.header__logo {
    padding-left: 1rem;
}

.header__container {
    background: var(--color-light);
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 2rem;
    border: 1px solid transparent;
    border-radius: 2rem;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
    padding: 2rem;
}

.header__container > div {
    background: transparent;
    padding: 2.4rem 5rem;
}

.header__copy {
    color: var(--color-copy);
    
}

.logo {
    padding-top: 1rem;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
}

.logo__copy {
    padding-top: 2rem;
}

.logo__copy h1 {
    color: var(--color-copy);
    letter-spacing: -4px;
    text-align: center;
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 5px;
    text-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
}

.logo__copy h3 {
    color: var(--color-copy);
    text-align: center;
    font-size: 50px;
    line-height: 50px;
    font-weight: 300;
    width: 100%;
    text-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.header__tagline {
    font-size: 50px;
    line-height: 50px;
    color: #b5c6fe;
    padding-bottom: 2rem;
    text-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
}

.header__copy p {
    font-size: 18px;
    padding-bottom: 2rem;
    text-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
}

.header__copy a {
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
    margin-top: 0rem;
}

/* --- Header Socials --- */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    right: 0;
    bottom: 20rem;
    position: fixed;
    font-size: 3rem;
}

.header__socials a {
    color: #678EFD;
}

/* --- MEDIA QUERIES (Medium devices/Tablets) --- */

@media screen and (max-width: 1024px) {

    header {
        margin-top: 3rem;
    }

    .header__container {
        grid-template-columns: 1fr;
    }

    .header__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .header__copy {
        padding: 1rem;
        padding-left: 2rem;
    }

    .header__socials {
        font-size: 1.5rem;
        transform: translateX(46%);
        padding-bottom: 1rem;
    }
}
  
/* --- MEDIA QUERIES (Small devices/Phones) --- */
  
@media screen and (max-width: 600px) {

    header {
        margin-top: 0.5rem;
    }

    .header__container {
        gap: 1rem;
        text-align: center;
    }

    .header__container > div {
        width: 100%;
        padding: 0rem 1rem 2rem 0.5rem;
        margin-left: -4px;
    }

    .cta {
        justify-content: center;
    }

    .header__socials {
        font-size: 1.5rem;
        position: sticky;
        top: 3rem;
        width: 100%;
        z-index: 100;
        flex-direction: row;
        transform: translateX(25%);
    }

    .header__tagline {
        font-size: 30px;
        line-height: 30px;
        margin-top: -2rem;
    }
}