#contact {
    margin-top: 1rem;
}

#contact h2 {
    color: var(--color-copy);
}

.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 9.8rem;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background: var(--color-light);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
}

.contact__option:hover {
    background: transparent;
    border: 1px solid;
}

.contact__option__icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #b5c6fe;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 1rem;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
}

.contact__option a:hover {
    transform: scale(1.2);
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
}

.contact__option h4,h5 {
    color: var(--color-copy);
}

/* --- FORM --- */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, 
textarea {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    padding:  1.5rem;
    border-radius: 0.5rem;
    background: var(--color-light);
    border: 2px solid transparent;
    resize: none;
    color: var(--color-copy);
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
}

/* --- MEDIA QUERIES (Medium devices/Tablets) --- */

@media screen and (max-width: 1024px) {

    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}
  
/* --- MEDIA QUERIES (Small devices/Phones) --- */
  
@media screen and (max-width: 600px) {

    .container.contact__container{
        width: var(--container-width-sm);
    }

    button {
       padding-left: 2rem;
    }
}

